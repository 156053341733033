.wrg-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  
}

.wrg-toggle-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.wrg-toggle-check, .wrg-toggle-uncheck {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  opacity: 0.3;
  transition: opacity .25s ease;
}
.wrg-toggle-check {
  left: 8px;
}
.wrg-toggle-uncheck {
  opacity: 1;
  right: 10px;
}

.wrg-toggle-uncheck span, 
.wrg-toggle-check span {
  align-items: center;
  display: flex;
  height: 10px;
  justify-content: center;
  position: relative;
  width: 10px;
}

.wrg-toggle-container{
  width: 55px;
  height: 28px;
  padding: 0;
  border-radius: 30px;
  /* background-color: #4d4d4d; */
  background-color: rgb(37, 37, 37);
  transition: all .2s ease;
  /* box-shadow: 0 0 6px 1px rgb(248, 204, 59); */
}

.wrg-toggle-circle{
  transition: all .5s cubic-bezier(.23,1,.32,1) 0ms;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 23px;
  /* border: 1px solid #999; */
  border-radius: 50%;
  background-color: transparent;
  /* background: linear-gradient(to left, rgb(255, 157, 65), rgb(255, 93, 64)); */
  box-sizing: border-box;
  transition: all .25s ease;
  opacity: 1;
}
/* .wrg-toggle:hover .wrg-toggle-circle{
  background-color: rgba(248, 240, 213, 0.5);
} */
/* background: linear-gradient(to left, #fafafa, rgb(255, 168, 87)); */
.wrg-toggle--checked .wrg-toggle-check{
  opacity: 1;
}
.wrg-toggle--checked .wrg-toggle-uncheck {
  opacity: 0.3;
}

.wrg-toggle--checked .wrg-toggle-circle {
  left: 27px;
}

@media(max-width:500px){
  .wrg-toggle-check, .wrg-toggle-uncheck {
    width: 8px;
    height: 8px;
   
  }
  .wrg-toggle-check span{
    margin-right:35px;
  }
  .wrg-toggle-uncheck span, 
  .wrg-toggle-check span {
    height: 9px;
    width:9px;
   
  }
  .wrg-toggle-container{
    width: 45px;
    height: 22px;
  }
}