.user-exit-edit {
  margin: 5px;
  padding: 1px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  z-index: 100;
}

.user-name-field {
  // color:#202647;
  font-size: 12px;
  padding: 1px 7px;
  border-radius: 10px;
}

.user-name {
  color: #f64c67;
  font-size: 12px;

}

.hid-in-mobile,
.exit,
.edit {
  font-size: 12px;
}

.exit-edit {
  display: flex;
  justify-content: center;
  font-size: 12px;
}

.edit,
.exit {
  padding: 5px;
  color: #f64c67 !important;
}

.space {
  padding: 5px;
}

@media only screen and (max-width: 991px) {
  .hid-in-mobile {
    display: none;
  }
}

.user-login {
  margin: 5px;
  padding: 1px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  z-index: 100;
}

.btnG {
  width: 80px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  box-sizing: border-box;
  border: 0;
  color: whitesmoke;
  background: #222;
  outline: none;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  /* box-shadow: 0 0 6px 1px rgba(255,120,23,1); */
}

.btnG .btn-textG {
  margin: 0 5px;
  color: #fff;
}

.btnG i span {
  font-size: 14px;
  color: rgb(255, 154, 21);
  text-shadow: 0 0 0 #cacaca;
}

.icon-cartG {
  margin: 0 6px;
}

.btnG:hover .icon-cartG,
.btnG:hover .btn-textG {
  color: #f64c67;
}

.rodal-dialog {
  background-color: #444 !important;
}

@media only screen and (max-width: 991px) {
  .user-name-field,

  .user-name,
  .hid-in-mobile,
  .exit,
  .edit {
    font-size: 11px !important;

  }

}