.footer {
  padding: 60px 0 0 0;
  background-color: rgb(247, 229, 230);
  margin-top: 30px;
}

.footer section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 1170px;
  margin: 0 auto;
  text-align: justify;
}

.footer-logo {
  width: 200px;
  margin-bottom: 10px;
}

.footer section>div {
  padding: 0 40px;
  /* color: #fff; */
}


.footer .title {
  font-size: 24px;
  font-weight: 700;
  position: relative;
  padding: 0 0 15px 0;
  /* color: #fff; */
}

.footer .title::before,
.footer img::before {
  content: "";
  width: 30%;
  height: 3px;
  background: orange;
  position: absolute;
  bottom: 0;
}

.footer .content div {
  padding: 0 0 15px 0;
}

.footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer ul li {
  display: block;
  padding: 7px 5px 7px 0;
}

.footer ul li a {
  display: block;
}

.footer i {
  margin: 10px 5px;
}

.footer .social li {
  border: none;
  padding: 2px 0;
}

.footer .copyright {
  width: 100%;
  display: block;
  padding: 13px 0 13px 0;
  text-align: center;
  font-size: 16px;
  /* border-top: 1px solid #666; */
  margin-top: 30px;
  background-color: #f8f7f7;
}

.section-title-footer .footer-contact-info {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.section-title-footer .footer-contact-info li {
  margin-bottom: 10px;
  color: #202647;
  position: relative;
  padding-right: 35px;
}

.section-title-footer .footer-contact-info li i {
  position: absolute;
  right: 0;
  top: 2px;
  font-size: 22px;
  color: #f64c67;
}

.section-title-footer .footer-contact-info li span {
  display: block;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 5px;
  color: #202647;
  padding-right: 5px;
}

.section-title-footer .footer-contact-info li a {
  display: inline-block;
  color: #202647;
  font-weight: 400;
  position: relative;
  font-size: 15px;
}

.section-title-footer .footer-contact-info li a:hover {
  color: #f64c67;
}


.section-title-footer .footer-contact-info li a:hover:before {
  width: 100%;
}


.section-title-footer .footer-contact-info li a:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  -webkit-transition: .5s;
  transition: .5s;
  background: -webkit-gradient(linear, left bottom, right top, from(#fdb084), color-stop(#ff9870), color-stop(#ff7e62), color-stop(#ff5f59), to(#ff3656));
  background: -webkit-linear-gradient(left bottom, #fdb084, #ff9870, #ff7e62, #ff5f59, #ff3656);
  background: linear-gradient(to right top, #fdb084, #ff9870, #ff7e62, #ff5f59, #ff3656);
}

.footer .copyright a {
  color: #f64c67;
}

.footer .copyright a:hover {
  color: #fdb084 !important;
}

.icon-footer-box {
  font-size: 14px;
  direction: rtl;
  display: flex;
  align-items: flex-end;
  text-align: center;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  text-align: center;
  box-sizing: border-box;
  z-index: 200;
}

.icon-footer-box .icon-holder {
  text-align: center;
  border-radius: 50%;
  margin: 5px 5px;
  padding: 5px 7px 0px 6px;
  box-sizing: border-box;
  /* color: #3f474c; */
  /* color: rgb(255, 150, 37); */
  color: #f64c67;
  font-size: 22px;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.4s ease-out;
  transform: translateY(70px);
}

.icon-footer-box .icon1 {
  transition: all 0.2s ease-out;
  font-size: 21px;
}

.icon-footer-box .icon1:hover {
  color: #fff;
  background: radial-gradient(circle farthest-corner at 35% 100%,
      #fec564,
      transparent 50%),
    radial-gradient(circle farthest-corner at 10% 140%,
      #feda7e,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(#6559ca,
      #bc318f 30%,
      #e42e66 50%,
      #fa5332 70%,
      #ffdc80 100%) !important;
  /* box-shadow: 2px 2px 3px #999; */
}


.icon-footer-box .icon2 {
  transition: all 0.4s ease-out;
}

.icon-footer-box .icon2:hover {
  background-color: #25d366;
  color: #fff;
}

.icon-footer-box .icon4 {
  transition: all 0.8s ease-out;
  font-size: 20px;
}

.icon-footer-box .icon4:hover {
  background-color: #e25454;
  color: #fff;
}

@media (max-width: 1169px) {
  .footer section {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .footer {
    padding-top: 20px;
  }

  .footer section {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .footer section:not(.copyright)>div {
    padding: 0 15px 30px 15px;
  }

  /* .footer .content {
    padding: 0 30px 0 0;
  } */
}

@media (min-width: 768px) and (max-width: 992px) {
  .footer section {
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }
}


@media (max-width: 991px) {
  .icon-footer-box .icon-holder {
    padding: 9px 6px 1px 5px;
  }
}

@media (max-width: 767px) {

  .column1,
  .column2,
  .column3 {
    text-align: center;
  }

  .column2 .bar,
  .column3 .bar {
    margin: auto;
  }

  .icon-footer-box {
    display: flex;
    align-content: center;
    justify-content: center;

  }

  .section-title-footer .footer-contact-info li i {
    right: 25%;
  }

  .footer .copyright div,
  .footer .copyright div a {
    font-size: 12px !important;
  }

  .footer ul .footer-li-mobile {
    display: flex;
    flex-direction: column-reverse;
  }

  .section-title-footer .footer-contact-info li {
    padding-right: 0;
  }

  .section-title-footer .footer-contact-info li i {
    position: static;
  }

}