*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nav-top {
  position: fixed;
  top: 0;
  left: 0;
  margin: 14px auto;
  padding: 2px;
  box-sizing: border-box;
  width: 100%;
  max-height: 40px;
  color: #222;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  /* background-color:  #eff5f8; */
  /* background-image: linear-gradient(to right, rgb(255, 133, 19) , rgb(255, 183, 27)); */
  z-index: 2500;
  font-family: "IranWebSans";
  font-size: 12px;
  /* box-shadow: 0px 4px 6px #fbc7cf; */
}

/* Toggle and UserLogin Box */
.user-login-gbs {
  display: none;
}

.toggle-icon {
  height: 100%;
  flex-basis: 10%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: left;
  margin-right: 8px;
}

/* Flag language */
.flag {
  left: 1px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
}

/* phone and address box */
.phone-adress {
  height: 100%;
  flex-basis: 80%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1px;
  margin-right: 70px;
  box-sizing: border-box;
  background: transparent;
}

.adressG {
  direction: rtl !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  background: transparent;
}

.icon-p-a {
  margin: 0 4px;
  /* text-shadow: 1px 1px 3px #fabb7c; */
  /* color: #333; */
  font-size: 10px;
}

.phoneG {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  margin: 0 23px;
  background: transparent;
}

/* searchBox box */
/* .searchboxG {
  flex-basis: 25%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  width: 255px;
  margin-right: 20px;
} */

.user-login-gbs {
  height: 100%;
  flex-basis: 30%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  /* padding: 4px; */
  box-sizing: border-box;
}

.toggle-icon {
  height: 100%;
  flex-basis: 5%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: left;
  margin: 0 8px;
}

.toggle-toolbar .toolbar-box {
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
  direction: rtl;
}

/* .toggle-toolbar  */
.icon-toolbar-box {
  font-size: 14px;
  direction: ltr;
  display: flex;
  align-items: flex-end;
  text-align: center;
  /* width: 100%; */
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  text-align: center;
  box-sizing: border-box;
  z-index: 200;
}

/* .toggle-toolbar  */
.icon-toolbar-box .icon-holder {
  text-align: center;
  border-radius: 50%;
  margin: 5px 5px;
  padding: 4px 9px 0px 9px;
  box-sizing: border-box;
  /* color: #3f474c; */
  /* color: rgb(255, 150, 37); */
  color: #f64c67;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.4s ease-out;
  transform: translateY(70px);
}

/* .toggle-toolbar  */
.icon-toolbar-box .icon1 {
  transition: all 0.2s ease-out;
}

/* .toggle-toolbar  */
.icon-toolbar-box .icon1:hover {
  color: #fff;
  background: radial-gradient(circle farthest-corner at 35% 100%,
      #fec564,
      transparent 50%),
    radial-gradient(circle farthest-corner at 10% 140%,
      #feda7e,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(#6559ca,
      #bc318f 30%,
      #e42e66 50%,
      #fa5332 70%,
      #ffdc80 100%) !important;
  /* box-shadow: 2px 2px 3px #999; */
}

/* .toggle-toolbar  */
.icon-toolbar-box .icon2 {
  transition: all 0.4s ease-out;
  /* font-weight: bold; */
}

/* .toggle-toolbar  */
.icon-toolbar-box .icon2:hover {
  background-color: #25d366;
  color: #fff;

  /* box-shadow: 2px 2px 3px #999; */
}

/* .toggle-toolbar  */
.icon-toolbar-box .icon3 {
  transition: all 0.6s ease-out;
  /* font-weight: bold; */
}

/* .toggle-toolbar  */
.icon-toolbar-box .icon3:hover {
  background-color: #355ad4;
  color: #fff;

  /* box-shadow: 2px 2px 3px #999; */
}

/* .toggle-toolbar  */
.icon-toolbar-box .icon4 {
  transition: all 0.8s ease-out;
}

/* .toggle-toolbar  */
.icon-toolbar-box .icon4:hover {
  background-color: #e25454;
  color: #fff;

  /* box-shadow: 2px 2px 3px #999; */
}

.toggle-toolbar .managementCard-img-icon:hover>img {
  transform: scale(1.25);
}

.toggle-toolbar .managementCard-img-icon:hover>.icon-holder-box {
  border: 1px solid #ffa50054;
  box-shadow: 1px 1px 15px 4px #00000078;
  visibility: visible;
  opacity: 1;
}

.toggle-toolbar .managementCard-img-icon:hover .icon1 {
  transform: translateY(-40px);
}

.toggle-toolbar .managementCard-img-icon:hover .icon2 {
  transform: translateY(-40px);
}

.toggle-toolbar .managementCard-img-icon:hover .icon3 {
  transform: translateY(-40px);
}

/* media=> screen max-width:500px  */
@media (max-width: 767px) {
  .nav-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .phone-adress {
    justify-content: center;
    align-items: baseline;
    height: 100%;
    font-size: 10px;
    margin: 1px;
  }

  .icon-p-a {
    margin: 0 2px;
    font-size: 10px;
  }

  .phoneG {
    margin-right: 6px;
  }

  .flag {
    width: 18px;
    height: 18px;
  }

  .toggle-toolbar .toolbar-box {
    visibility: hidden;
  }
}

/* media=> screen max-width:991px  */
@media (max-width: 991px) {
  .nav-top {
    width: 100%;
    min-height: 56px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-evenly;
    position: fixed;
    top: 56px;
    left: 0;
    /* background-color: #fff; */
    /* background-color: rgb(255, 150, 37); */
    /* border-top: solid 1px #333; */
  }

  .user-login-gbs {
    display: none;
  }

  .toggle-toolbar .toolbar-box {
    visibility: hidden;
  }
}