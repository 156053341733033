html {
  scroll-behavior: smooth;
}

p,
span,
div,
ul,
li,
a {
  font-size: 16px;
  font-weight: 100;
}

/* nav slider */
.pcoded-navbar:not(.theme-horizontal) .navbar-content {
  height: calc(100% - 56px);
}

/* search */
.search-bar {
  display: block;
}

/* menu */
.pcoded-navbar {
  .pcoded-inner-navbar {
    >li {
      >a.active {
        background: rgba(202, 202, 202, 0.3);

        >span {
          color: #f64c67;
        }

        &:before {
          background: $primary-color;
        }
      }
    }

    >li:hover {
      >a {
        >span {
          color: #f64c67;
        }
      }
    }

    li {
      >a.active {
        font-weight: 600;
      }

      .pcoded-submenu>li {

        &.active,
        &:focus,
        &:hover {

          >a,
          >a.active {
            &:before {
              background: $primary-color;
            }
          }
        }

        >a.active {
          &:before {
            background: $primary-color;
          }
        }
      }
    }

    >.pcoded-menu-caption {

      &.active,
      &.pcoded-trigger {
        &:after {
          display: none;
        }
      }
    }
  }
}

/* box-layout */
body.box-layout .pcoded-navbar {
  height: 100%;
}

/* menu-styler */
.menu-styler {

  h5,
  h6 {
    color: #222 !important;
  }
}

/* datatable icons */
.dataTables_wrapper table.dataTable {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border: none !important;
}

table.dataTable .sorting:after,
table.dataTable .sorting_asc:after,
table.dataTable .sorting_desc:after {
  top: 16px;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  font-family: feather !important;
  content: "\e82a";
}

table.dataTable thead .sorting_asc:after {
  content: "\e82d";
}

table.dataTable thead .sorting_desc:after {
  content: "\e82a";
}

/* card header */
.card .card-header+.collapse {
  .card-body {
    padding-top: 0;
  }
}

/* notification */
.css-e110bw {
  top: 70px !important;

  .alert-inverse .close {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.user-profile-list table {
  border-collapse: separate;
  border-spacing: 0 10px !important;
}

.user-profile-list table th,
.user-profile-list table td {
  padding: 1.05rem 0.75rem !important;
}

.user-profile-list table th {
  position: relative;
}

.user-profile-list table th .feather {
  position: absolute;
  bottom: 19px;
  right: 8px;
  display: block;
  opacity: 0.8;
}

.user-profile-list {
  .form-control {
    background: transparent;
  }
}

.modal-footer {
  padding: 20px 25px !important;
}

.react-toast-notifications__container {
  margin-top: 62px;
  z-index: 1051 !important;

  .alert-dismissible .close {
    top: -12px;
    right: -66px;
  }
}

.rodal-dialog {
  height: auto !important;
  bottom: auto !important;
  top: 50% !important;
  transform: translateY(-50%);

  .card {
    box-shadow: none;
    margin-bottom: 0px;

    .card-footer {
      padding-bottom: 0px;
    }
  }
}

.section-title-footer .bar {
  height: 4px;
  width: 110px;
  background: -webkit-gradient(linear,
      left bottom,
      right top,
      from(#fdd084),
      color-stop(#ffb870),
      color-stop(#ff9e62),
      color-stop(#ff7f59),
      to(#ff5656));
  background: linear-gradient(to right top,
      #fdd084,
      #ffb870,
      #ff9e62,
      #ff7f59,
      #ff5656);
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  // margin: auto;
}

.section-title-footer .bar::before {
  content: "";
  position: absolute;
  left: 0;
  right: 80;
  top: 0;
  height: 100%;
  width: 5px;
  background: #fff;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}

@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(85px);
  }
}

@keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(85px);
    transform: translateX(85px);
  }
}

@media only screen and (max-width: 1199px) {
  .hover-blk {
    display: none;
  }
}

@media (max-width: 750px) {
  .q-view .content {
    width: 450px;
  }
}

@media (max-width: 460px) {
  .q-view .content {
    width: 300px;
  }
}

.d-flex {
  i {
    margin-right: 5px;
  }
}

.s-header {
  display: block;
  font-size: small;
  text-align: center;
  background-color: #dfe8ea;
  padding: 7px;

  &~.m-0 {
    font-size: 9.5pt;
  }
}

.s-border-b {
  display: block;
  height: 1px;
  margin: 4px 0px;
  background-image: linear-gradient(90deg, transparent, #d4d4d4, transparent);
}

.s-no-rows {
  text-align: center;
  color: #888;
  font-size: 8.5pt;
}

// prouduct
.productitem {
  .see {
    top: 56%;
    right: 20%;
  }

  .like {
    top: 56%;
    right: 5%;
  }
}

// MainSlider
.Mainslider {
  .sub-leftstyle {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

// NavTop
.nav-top {
  direction: rtl;
}

// NavSearchBox
.SearchBoxG {
  direction: rtl;
}

.SearchBoxG input {
  direction: ltr;
}

// NewUser
.label-reg {
  direction: ltr;
}

// SignIn
.signin-gbs .signin-contents {
  direction: ltr;
}

// UsePanel
.UserPanel {
  top: 35px;
  right: 47px;
  left: initial;
  border-top-right-radius: 5px;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 5px;
}

.name-userpanel {
  border-top-right-radius: 5px;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
}

.content-userpanel {
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 5px;
}

@media (max-width: 991px) {
  .UserPanel {
    top: 55px;
    right: 10%;
    left: initial;
  }
}

// -----------
.b-brand-gbs {
  margin: 1px;
  width: 100px;
  height: 59px;
  padding: 1px;
  margin-left: 45px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // flex-basis: 10%;
  z-index: 90;

  .logo {
    width: 100px;
    height: 59px;
    // margin-left: 45px;
    padding: 2px;
    box-sizing: border-box;
  }
}

// SearchBox
.serachbox-gbs {
  width: 60px;
  height: 52px;
  border: 0;
  margin: 5px 10px;
  padding-top: 5px;
  box-sizing: border-box;
  outline: none;
  background: transparent;
  z-index: 100;
  flex-basis: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: inherit;
  border-radius: 10%;
  position: relative;
}

.serachbox-gbs {
  .serachbox-navsearchbox {
    margin: 5px auto;
    padding: 2px 0;
    width: 420px;
    height: 60px;
    transition: all 0.5s ease-out;
    position: absolute;
    top: 32px;
    left: initial;
    right: -14px;
    z-index: 150;
    background: #8d8c8c61;
    // rgb(215 213 210 / 39%)
    border-radius: 5px;
  }
}

// NavTop
.icon-p-a {
  transform: scaleX(-1);
}

.addtocart_continer {
  .cartbtn {
    right: inherit;
    left: 8px;
    flex-direction: row-reverse;
  }
}

.addtocart_continer {
  .oldprice {
    left: inherit;
    right: 15px;
  }
}

.addtocart_continer {
  .newprice {
    left: inherit;
    right: 5px;
  }
}

.form-control {
  direction: rtl;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ffd8c0;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(255 150 64 / 25%);
}

.margin-from-header {
  margin-top: 70px;
}

.modal-content {
  border: 3px solid #ffd8c0;
}

.RFS-StepperContainer {
  font-family: "iransansweb" !important;
  padding: 18px !important;
}

// .RFS-ConnectorContainer {
//   left: calc((50% + 35px) - 8px) !important;
//   right: calc((-50% + 35px) - 8px) !important;
// }
.btn-signIn {
  margin-top: 10px;
  width: 100%;
  height: 40px;
  border-radius: 15px;
  background: #3b4650;
  outline: none;
  border: 0;
  color: rgb(255, 255, 255);
}

.register-signin {
  width: 90%;
  height: 45px;
  background: transparent;
  padding: 2px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}


.peykPicture {
  width: 400px;

}

.section-is-title h2 {
  padding-top: 30px;
  font-size: 30px;
  margin: 0 0 25px;
}

.section-title .bar {
  height: 4px;
  width: 110px;
  background: linear-gradient(to right top, #fdb084, #ff9870, #ff7e62, #ff5f59, #ff3656);
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin: auto;
}

.section-title .bar::before {
  content: "";
  position: absolute;
  left: 0;
  right: 80;
  top: 0;
  height: 100%;
  width: 5px;
  background: #fff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}

@media only screen and (max-width: 991px) {
  .peykPicture {
    width: 300px;
  }

  p,
  span,
  div,
  ul,
  li,
  a {
    font-size: 1rem !important;
  }

  h1,
  h2 {
    font-size: 2.9rem !important;
  }


  h3 {
    font-size: 1.7rem !important;
  }

  h4,
  h5,
  h6 {
    font-size: 1.1rem !important;
  }

  .footer-logo {
    width: 8rem !important;

  }


}

@media only screen and (max-width: 990px) {

  p,
  span,
  div,
  ul,
  li,
  a {
    font-size: 0.9rem !important;
  }

  h1,
  h2 {
    font-size: 2.5rem !important;
  }


  h3 {
    font-size: 1.5rem !important;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem !important;
  }


}

@media only screen and (max-width: 1990px) {

  .register-signin p,
  .register-signin p a {
    font-size: 12px !important;
  }
}




@media only screen and (max-width: 350px) {
  .peykPicture {
    width: 200px;
  }
}

@media only screen and (max-width: 250px) {
  .peykPicture {
    width: 120px;
  }
}


.text-surati {
  color: #f64c67;
}

.ui-pnotify {
  right: 0 !important;
  // bottom: 0 !important;
}


.slider-food {
  height: 350px;

}

.hooya-cart-icon {
  position: relative;
  display: inline-block;
}

.hooya-cart-icon i {
  font-size: 1.5em;
}

.hooya-cart-icon .count {
  background: #ffffff;
  color: #666666;
  position: absolute;
  top: -10px;
  right: 20px;
  width: 1.5em;
  height: 1.5em;
  // z-index: 999;
  border: 1px solid #fbe4d4;
  font-size: 0.8em;
}