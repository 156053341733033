.signin-gbs {
    width: 100%;
    height: 550px;
    box-sizing: border-box;
    /* background:#444; */
    font-family: 'IranWebSans';
    /* border-radius:15px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* border: 1px solid black; */
    padding: 20px;
}

.signin-gbs .signin-logo-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 1px;
    padding-top: 10px;
    box-sizing: border-box;
    background: transparent;
}

.signin-logo-title img {
    width: 200px;
    height: 100px;
    margin-bottom: 15px;
}

.signin-logo-title .signin-title {
    text-align: center;
    font-size: 22px;
    font-family: 'IranWebSans';
    color: #3b4650;
    margin: 5px;
}

.signin-gbs .signin-contents {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-top: 20px;
    box-sizing: border-box;
    background: transparent;

}

.signin-contents .content-input {
    width: 90%;
    height: 50px;
    padding: 10px;
    margin: 10px auto;
    border: 1px solid rgb(133 87 37);
    border-radius: 15px;
    box-sizing: border-box;
    background: #222;
    box-shadow: 0 0 5px 1px rgb(69, 69, 69);
    outline: none;
    overflow: hidden;
    color: #fff;
}

.signin-contents .signin-checkbox {
    width: 88%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: inherit;
    align-items: center;
    text-align: initial;
    background: trancparent;
    padding: 5px;
    box-sizing: border-box;
}

.signin-checkbox .signin-label {
    color: #a8a3a3;
    padding-top: 7px;
    /* flex-basis:90%; */
    font-size: 13px;
}

.signin-checkbox .checkbox-input {
    width: 18px;
    height: 18px;
    flex-basis: 10%;
    border-radius: 5px;
    border: 0;
    background: #222;

}

.signin-contents .btn-signIn {
    margin-top: 10px;
    width: 90%;
    height: 40px;
    border-radius: 15px;
    background: #3b4650;
    outline: none;
    border: 0;
    color: rgb(255, 255, 255);
}

.register-signin {
    width: 90%;
    height: 45px;
    background: transparent;
    padding: 2px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.register-signin p {
    color: #a8a3a3;
    /* height:5px; */
    font-size: 11px;
}

@media(max-width:991px) {
    .signin-gbs {
        width: 75%;
        height: 550px;
    }


}

@media(max-width:767px) {

    .register-signin p a {
        font-size: 12px !important;
    }

}

/* .banner-btn {
    margin-top: 30px;
} */

.default-btn::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f64c67;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
}

.default-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: -webkit-gradient(linear, left bottom, right top, from(#fdb084), color-stop(#ff9870), color-stop(#ff7e62), color-stop(#ff5f59), to(#ff3656));
    background: linear-gradient(to right top, #fdb084, #ff9870, #ff7e62, #ff5f59, #ff3656);
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.default-btn {
    display: inline-block;
    padding: 12px 35px;
    color: #ffffff;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 1;
}

.default-btn:hover::after {
    opacity: 1;
    visibility: visible;
}

.default-btn:hover {
    color: #ffffff;
}

.login-btn {
    width: 48%;
    padding: 6px;
}

.register-link {
    padding: 4px 8px;
    background-color: #c2c2c2;
    color: #202647;
    border-radius: 5px;
}

.register-link:hover {
    background-color: #f64c67;
    color: #fff;

}