/* ------------------regiser-------------- */
.signin-gbs-reg {
    width: 100%;
    height: 570px;
    padding: 1px;
    box-sizing: border-box;
    font-family: 'IranWebSans';
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    /* border: 1px solid black; */
    padding: 20px;
    justify-content: center;

}

.signin-gbs-reg .signin-logo-reg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 1px;

    padding-top: 10px;
    box-sizing: border-box;
    background: transparent;
}

.signin-logo-reg .reg-title {
    text-align: center;
    font-size: 18px;
    font-family: 'IranWebSans';
    color: #3b4650;
    padding-top: 10px;
    box-sizing: border-box;
}

.signin-logo-reg img {
    width: 150px;
    height: 90px;
    margin-top: 8px;
}

.signin-contents-reg {
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-top: 8px;
    box-sizing: border-box;
    background: transparent;
}

.reg-inputs {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: initial;
    direction: ltr;
}

.label-reg {
    flex-basis: 30%;
    color: #3b4650;
    font-size: 13px;
    display: flex;
}

.signin-contents-reg .btn-reg {
    margin-top: 3px;
    width: 90%;
    height: 40px;
    border-radius: 15px;
    background: #3b4650;
    outline: none;
    border: 0;
    color: rgb(255, 255, 255);
}

.register-signin-reg {
    /* width: 90%; */
    height: 45px;
    background: transparent;
    padding: 2px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.register-signin-reg p {
    color: #a8a3a3;
    height: 5px;
    font-size: 12px;
}

@media(max-width:991px) {
    .signin-gbs-reg {
        width: 75%;
        height: 570px;
    }

    .reg-inputs {
        width: 95%;
        /* height: 35px; */
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        text-align: initial;
        background: transparent;
    }
}