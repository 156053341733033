.SearchBoxG{
    width: 150px;
    height: 30px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    border: 0;
    border-radius: 28px;
    margin:5px 12px;
    padding: 2px 5px;
    box-sizing: border-box;
    outline: none;
    overflow: hidden;
    background: #222;
    z-index: 100;
    /* direction: ltr; */
    /* box-shadow: 0 0 6px 1px rgba(255,120,23,1); */
}
.SearchBoxG input{
    flex-grow: 1;
    margin: 0.25rem;
    padding: 0;
    padding:0 10px;
    border: 0;
    box-sizing: border-box;
    outline: none;
    width: 30rem;
    height: 2.5rem;
    align-items: center;
    background: #222;
    /* direction: rtl; */
    line-height: 28px;
    color: #FFF;
    font-size: 12px;
}
.SearchG{
    padding: 2px;
    margin: 1px 4px;
    border: 0;
    box-sizing: border-box;
    color: white;
    align-items: center;
    cursor: pointer;
    background: #222;
    text-shadow: 1.5px 1px 2px #5f5f5f;
}
.SearchG .iconG{
    display: flex;
    align-items: center;
}
.input-en{
    text-align: left;
}

/* ---------------- */
@media(min-width:768px){
    .SearchBoxG{
        width: 200px;
        height: 30px;
    }
}
@media(max-width:991px){
    .SearchBoxG{
        width: 200px;
        height: 30px;
    }
}

@media(min-width:992px){
    .SearchBoxG{
        margin:5px auto;
        padding: 2px 0;
        width: 400px;
        height: 45px;
        transition: width 1s;
        /* position: absolute ;
        top: 33px;
        left: 9px; */
        z-index: 150;
        background: #222;
        border-radius: 5px;
        border: 1px solid #fcb12769;
    }
    .SearchBoxG input{
        /* display: none;
        visibility:hidden;
        opacity:0; */
        background: #222;
    }
    /* .SearchBoxG:hover{
        width: 280px;
        transition: width 0.8s;
    } */
    /* .SearchBoxG:hover input{
        display: block;
        visibility:visible;
        opacity:1;
    } */
    .SearchBoxG:hover .SearchG i{
        color: rgb(253, 164, 47);
        background: transparent;
    }
    .SearchG{
        background: transparent;
    }
}


