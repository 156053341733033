.cart-shopG{
    border: 0;
    background: transparent;
    opacity: 1;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    text-align: center;
    margin:5px;
    position: relative;
    z-index: 99;
 }
 .cart-shopG i{
     color: #222;
     font-size: 20px;
     font-weight: 600;
     transition: opacity 1s;
     align-items: center;
     text-align: center;
     margin: 0px;
     /* text-shadow: 0 0 4px #626262; */
 }
 .cart-shopG:hover i{
     color:#222;
     opacity:0.7;
     transition: opacity 1s;
 }
 .cart-shop-count{
    width:20px;
    height:20px;
    padding:3px;
    box-sizing:border-box;
    border: 1px solid orange;
    background: #333;
    border-radius:50%;
    box-sizing:border-box;
    color:#fff;
    font-size:10px;
    position:absolute;
    top:9px;
    left:10px;
 }

 @media (max-width: 991px) {
    .cart-shopG i{
       color: rgb(255, 150, 37);
    }
    .cart-shop-count{
        border: 1px solid #222;
        background: rgb(255, 179, 97);
        color:#222;
     }
 }
